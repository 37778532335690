import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./assets/logo.png";

const Container = styled.div`
  height: 135px;
  display: flex;
  padding: 0 200px;
  background: var(--main-color);
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding: 25px 0;
    flex-direction: column;
    justify-content: center;
    height: unset;
  }
`;
const LogoImage = styled.img`
  width: 150px;
`;
const TextWrapper = styled.div`
  display: flex;
  margin-left: 30px;
  text-align: right;
  font-size: 11px;
  color: white;
  font-weight: 400;
  font-family: normal;
  line-height: 1.5;
  flex-direction: column;

  a {
    color: inherit;
    font-size: 11px;
  }
  span {
    margin: 0 5px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    margin-left: 0;
    margin-top: 20px;

    > div {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;

      span {
        margin: 0;
        height: 0;
        display: block;
        visibility: hidden;
      }
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <LogoImage src={logo} />
      <TextWrapper>
        <div>
          회사명 : 아임스타그램 <span>|</span>대표 : 이선혜<span>|</span>
          대표전화 : 010-4077-0832
        </div>
        <div>
          사업자등록번호 : 297-18-01909<span>|</span>
          주소 : 서울특별시 강동구 천호대로186길 41
        </div>

        <div>
          <Link target="_blank" to="/terms/privacy">
            개인정보처리방침
          </Link>
          <span>|</span>{" "}
          <Link target="_blank" to="/terms/service">
            서비스이용약관
          </Link>
        </div>
      </TextWrapper>
    </Container>
  );
};

export default Footer;
